import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import Layout from '../components/layout'
import SEO from '../components/seo'
import CardPost from '../components/cards/post'
import StructuredData from '../components/structured-data'

function BlogPage({ data }) {
  return (
    <Layout>
      <SEO title="Blog" slug="/blog" />
      <StructuredData pageType="webPage" slug="blog" pageTitle="Blog" />
      <div className="container">
        <h1 className="text-3xl lg:text-5xl mb-10 leading-tight">
          Real Estate Shows Blog
        </h1>
        <ul className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-8 xl:gap-12 mb-10">
          {data.wordpress.posts.nodes.map(post => (
            <li key={post.id}>
              <CardPost {...post} />
            </li>
          ))}
        </ul>
      </div>
    </Layout>
  )
}

BlogPage.propTypes = {
  data: PropTypes.object,
}

export const query = graphql`
  query {
    wordpress {
      posts {
        nodes {
          title
          id
          slug
          date
          author {
            name
            avatar {
              url
              imageFile {
                childImageSharp {
                  fixed(width: 60, height: 60, quality: 100) {
                    ...GatsbyImageSharpFixed_withWebp_tracedSVG
                  }
                }
              }
            }
          }
          categories(where: { exclude: "1" }) {
            nodes {
              name
            }
          }
        }
      }
    }
  }
`

export default BlogPage
