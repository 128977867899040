import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import dayjs from 'dayjs'

function CardPost({ title, slug, author, date, categories }) {
  return (
    <article className="bg-purple-100 rounded-lg shadow-sm hover:shadow h-full">
      <Link
        aria-label={title}
        className="block h-full flex flex-col"
        to={`/blog/${slug}`}
      >
        <div
          className="relative mb-10 pb-40 bg-repeat rounded-t-lg bg-purple-800"
          style={{
            backgroundImage: `url("data:image/svg+xml,%3Csvg width='100' height='20' viewBox='0 0 100 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.184 20c.357-.13.72-.264 1.088-.402l1.768-.661C33.64 15.347 39.647 14 50 14c10.271 0 15.362 1.222 24.629 4.928.955.383 1.869.74 2.75 1.072h6.225c-2.51-.73-5.139-1.691-8.233-2.928C65.888 13.278 60.562 12 50 12c-10.626 0-16.855 1.397-26.66 5.063l-1.767.662c-2.475.923-4.66 1.674-6.724 2.275h6.335zm0-20C13.258 2.892 8.077 4 0 4V2c5.744 0 9.951-.574 14.85-2h6.334zM77.38 0C85.239 2.966 90.502 4 100 4V2c-6.842 0-11.386-.542-16.396-2h-6.225zM0 14c8.44 0 13.718-1.21 22.272-4.402l1.768-.661C33.64 5.347 39.647 4 50 4c10.271 0 15.362 1.222 24.629 4.928C84.112 12.722 89.438 14 100 14v-2c-10.271 0-15.362-1.222-24.629-4.928C65.888 3.278 60.562 2 50 2 39.374 2 33.145 3.397 23.34 7.063l-1.767.662C13.223 10.84 8.163 12 0 12v2z' fill='%23a491ca' fill-opacity='1' fill-rule='evenodd'/%3E%3C/svg%3E")`,
          }}
        >
          <div className="absolute top-0 left-0 w-full h-full z-20 rounded-t-lg bg-purple-800 opacity-75"></div>

          {author && author.avatar && (
            <Img
              className="rounded-full ml-4 z-30 border-2 border-purple-100"
              fixed={author.avatar.imageFile.childImageSharp.fixed}
              style={{ position: `absolute`, bottom: `-30px` }}
            />
          )}
        </div>
        <div className="px-4 pb-4 flex flex-col h-full">
          {categories && categories.nodes && categories.nodes[0] && (
            <div className="text-purple-700 uppercase font-semibold text-xs mb-2">
              {categories.nodes[0].name}
            </div>
          )}
          <h2
            className="text-xl md:text-2xl text-purple-900 leading-tight mb-6"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <div className="mt-auto text-xs flex items-center text-gray-700">
            <time className="font-semibold" dateTime={date}>
              {dayjs(date).format('MMM D, YYYY')}
            </time>
            {author && <div className="ml-1 text-xs">- {author.name}</div>}
          </div>
        </div>
      </Link>
    </article>
  )
}

CardPost.propTypes = {
  title: PropTypes.string,
  slug: PropTypes.string,
  author: PropTypes.object,
  date: PropTypes.string,
  categories: PropTypes.shape({
    nodes: PropTypes.array,
  }),
}

export default CardPost
